import React from "react";
import PropTypes from "prop-types";
import showdown from "showdown";

showdown.extension("targetlink", function () {
  return [
    {
      type: "lang",
      regex: /\[((?:\[[^\]]*]|[^\[\]])*)]\([ \t]*<?(.*?(?:\(.*?\).*?)?)>?[ \t]*((['"])(.*?)\4[ \t]*)?\)\{\:target=(["'])(.*?)\6}/g,
      replace: function (wholematch, linkText, url, a, b, title, c, target) {
        var result = '<a href="' + url + '"';

        if (typeof title != "undefined" && title !== "" && title !== null) {
          title = title.replace(/"/g, "&quot;");
          title = showdown.helper.escapeCharacters(title, "*_", false);
          result += ' title="' + title + '"';
        }

        if (typeof target != "undefined" && target !== "" && target !== null) {
          result += ' target="' + target + '"';
          result += ' rel="noopener noreferrer"';
        }

        result += ">" + linkText + "</a>";
        return result;
      },
    },
  ];
});

const conv = new showdown.Converter({
  extensions: ["targetlink"],
});

// const converter = new showdown.Converter()

const MarkdownContent = ({ content, className }) => (
  <div
    className={className}
    dangerouslySetInnerHTML={{ __html: conv.makeHtml(content) }}
  />
);

MarkdownContent.propTypes = {
  content: PropTypes.string,
  className: PropTypes.string,
};

export default MarkdownContent;
