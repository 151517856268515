import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import Layout from "../components/Layout";
import Features from "../components/Features";
import Testimonials from "../components/Testimonials";

export const ProductPageTemplate = ({
  image,
  title,
  heading,
  description,
  intro,
  main,
  testimonials,
  fullImage,
  pricing,
}) => (
  <div className="content">
    <div className="jumbo-header jumbo-header-products shadow-line-lite">
      <div className="jumbo-slogan container columns">
        <div className="column is-10 is-offset-1">
          <h1
            className="has-text-weight-bold is-size-4-mobile is-size-4-tablet is-size-4-widescreen"
            style={{
              color: "#404040",
              lineHeight: "1",
              padding: "0.25em",
            }}
          >
            {/* {title} */}
            WYŁĄCZNIKI NOŻNE DLA PRZEMYSŁU I MEDYCYNY
          </h1>

          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-5-widescreen"
            style={{
              color: "#767171",
              lineHeight: "1",
              padding: "0.25em",
              margin: "0",
            }}
          >
            {/* {subheading} */}
            Wybierz urządzenie optymalnie dopasowane do Twojej aplikacji.
          </h3>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-5-widescreen"
            style={{
              color: "#767171",
              lineHeight: "1",
              padding: "0.25em",
              margin: "0",
            }}
          >
            {/* {subheading} */}
            Zredukuj koszty i ciesz się bezproblemową eksploatacją.
          </h3>
        </div>
      </div>
      {/*<div className="jumbo-product">
             <img src={productHeader} alt="Wyłącznik nożny steute"/>             
        </div>*/}
    </div>

    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-offset-1">
              <h3 className="has-text-weight-semibold is-size-2">{heading}</h3>
              <p className="is-size-4 has-text-justified">{description}</p>
            </div>
          </div>
          <div className="columns" id="featured-products">
            <div className="column is-10 is-offset-1">
              <Features gridItems={intro.blurbs} />
              <Testimonials testimonials={testimonials} />
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);

ProductPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  main: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    image1: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    image3: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  }),
  testimonials: PropTypes.array,
  fullImage: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  pricing: PropTypes.shape({
    heading: PropTypes.string,
    description: PropTypes.string,
    plans: PropTypes.array,
  }),
};

const ProductPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <ProductPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        description={frontmatter.description}
        intro={frontmatter.intro}
        main={frontmatter.main}
        testimonials={frontmatter.testimonials}
        fullImage={frontmatter.full_image}
        pricing={frontmatter.pricing}
      />
    </Layout>
  );
};

ProductPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default ProductPage;

export const productPageQuery = graphql`
  query ProductPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 300, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            title
            text
            linkgroup
          }
          heading
          description
        }
        main {
          heading
          description
          image1 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image2 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 526, quality: 92) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          image3 {
            alt
            image {
              childImageSharp {
                fluid(maxWidth: 1075, quality: 72) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
        testimonials {
          author
          quote
        }
        full_image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        pricing {
          heading
          description
          plans {
            description
            extra
            items
            plan
            price
          }
        }
      }
    }
  }
`;
