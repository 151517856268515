import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import MarkdownContent from "../components/MarkdownContent";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const FeatureGrid = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item) => (
      <div key={item.title} className="column is-6" id={item.linkgroup}>
        <div className="has-text-centered">
          <div
            style={{
              width: "300px",
              display: "inline-block",
            }}
          >
            <PreviewCompatibleImage imageInfo={item} />
          </div>
        </div>
        <h3 className="margin-top-0">{item.title}</h3>
        <MarkdownContent content={item.text} />
        {item.arrowlink && (
          <AnchorLink className="btn-arrow" to={`/produkty#${item.linkgroup}`}>
            Sprawdź <span className="text-arrow">→</span>
          </AnchorLink>
        )}
      </div>
    ))}
  </div>
);

FeatureGrid.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
      text: PropTypes.string,
    })
  ),
};

export default FeatureGrid;
